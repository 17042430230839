.btn-primary:active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}

.custom-navbar {
  width: 100%;
  background-color: #FFFFFF !important;
}

nav.web-navbar {
  position: fixed !important;
  background: #FFFFFF;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  padding: 20px 0 !important;
  min-width: 100vw;
  z-index: 9;

  .navbar-main {
    position: relative !important;
    padding-top: 0rem !important;
    display: block;
    min-width: 1400px;
  }

  .navbar-search {
    background: #161616;
    border: 0.5px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 15px 20px;

    input {
      color: #fff;
      background-color: transparent;
      margin-left: 10px;
    }
  }

  .navbar-collapse {
    flex-grow: 0;
    border: none;
    border-radius: 100px;
    width: auto;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    padding: 10px 0;
  }

  .navbar-light .navbar-nav .nav-link {
    margin: 0 25px;
  }

  a.navbar-brand {
    margin: 0 24px;
  }

  a.nav-link {
    margin: 0 20px;
    font-size: 18px;
    font-family: 'Roboto';
    color: #000000;
    font-weight: 700;

    // img {
    //   display: none;
    // }
  }

  .logo-pill {
    position: absolute;
    bottom: -6px;
    left: 56%;
  }

  .nav-link {
    color: #000000 !important;
    font-family: 'Roboto';
    font-size: 18px;
    display: inline-block;
    text-decoration: none;
  }

  .connect-btn {
    // background: linear-gradient(317.15deg, #860a1f 5.82%, #fb012e 98.45%) !important;
    font-family: 'Roboto';
    font-size: 12px;
    background: linear-gradient(137deg, #EDDFB2 0%, #EDD37E 100%) !important;
    border-radius: 23px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 10px;
    color: #3B3B3B !important;
    border: 1px solid #ffffff99;
    height: 32px !important;

    &:hover,
    &:focus {
      color: #3B3B3B !important;
      // background-color: #000000 !important;
      background: linear-gradient(137deg, #EDDFB2 0%, #EDD37E 100%) !important;
    }

    &::after {
      display: none;
    }
  }

  .dropdown-item:hover,
  .dropdown-item:focus {
    color: #edd37e;
    // background-color: #2f2f30;
    background: none !important;
  }

  .dropdown-item {
    opacity: 1;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    /* text-decoration: none; */
    background: none !important;
  }

}

nav.web-navbar .dropdown-item.active,
nav.web-navbar .dropdown-item:active {
  background: none !important;
  opacity: 1;
}

@media (max-width: 991px) {
  .web-navbar {
    .user-icon {
      display: none;
    }

    .account-menu {
      display: block;
    }

    .nav-link {
      display: flex;
      align-items: center;
      padding: 7px 0 7px 0 !important;

      img {
        margin-right: 15px;
        width: 12px;
      }
    }

    a.nav-link {
      img {
        display: none;
      }
    }

    .navbar-nav {
      &.px-3 {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    .community-drp {
      display: none;
    }

    .connect-btn {
      &.ms-2 {
        margin: 0 !important;
      }
    }

    .sub-account-menu {
      li {
        padding: 5px 0;

        a {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          text-decoration: none;
          color: #EDD37E;

          .menuCount {
            font-family: 'Prompt';
            font-size: 10px;
            border: solid 1px #EDD37E;
            padding: 3px 9px;
            border-radius: 51px;
            vertical-align: middle;
          }
        }
      }
    }

    .header-social-icons {
      ul {
        margin: 30px 0;
        padding: 10px 0;
        text-align: center;
        // border-top: 0.5px solid #343434;
        display: flex;

        li {
          display: inline-block;
          margin: 10px;
          width: 41px;
          height: 41px;
          background: #000000 0% 0% no-repeat padding-box;
          border: 1px solid #707070;
          opacity: 1;
          backdrop-filter: blur(30px);
          -webkit-backdrop-filter: blur(30px);
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          a {
            display: inline-block;
          }
        }
      }
    }
  }
}

.web-navbar .btn {
  font-family: 'Roboto';
  // border:1px solid #ffffff2b;
  background: transparent !important;
  border: 1px solid #FFFFFF2B;
  border-radius: 51px;

  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  font-size: 12px;
  height: fit-content;
}

.web-navbar .dropdown-menu,
.web-navbar .show>.btn-primary.dropdown-toggle {
  backdrop-filter: blur(10px);
  background: rgba(0,0,0,.8);
  border: 1px solid hsla(0,0%,100%,.17);
  border-radius: 23px;
  box-sizing: border-box;
  z-index: 1;
}

.web-navbar .dropdown-menu {  
  margin-bottom: 8px;
  margin-top: 14px !important;
  padding: 10px;
  background-color: #000000;
}

.web-navbar .rewards .dropdown-menu{
  margin-top: 12px !important;
  padding: 10px;
}

.web-navbar .dropdown-menu a {
  color: #FFFFFF;
  font-family: 'Roboto' !important;
  font-size: 15px !important;
  font-weight: 500;
  text-decoration: none !important;
}

.web-navbar .rewards .dropdown-menu a {
  color: #edd37e;
  font-family: 'Roboto' !important;
  font-size: 15px !important;
  font-weight: 500;
}


nav.web-navbar .dropdown-item.active,
nav.web-navbar .dropdown-item:active {
  background: none !important;
  opacity: 1 !important;
}

.btnWhite {
  font-family: 'Roboto';
  font-size: 15px;
  background-color: #ffffff;
  display: flex;
  padding: 0 23px;
  align-items: center;
  height: 46px;
  color: #3B3B3B !important;
  border-radius: 51px;
  border: 1px solid #ffffff99;
  text-decoration: none;

  &:hover,
  &:focus {
    color: #fff !important;
    background-color: #000000;
  }

  &::after {
    display: none;
  }
}

.btnWhiteBordered {
  font-family: 'Roboto';
  font-size: 15px;
  background-color: transparent;
  padding: 0 23px;
  display: flex;
  align-items: center;
  height: 46px;
  color: #fff;
  border-radius: 51px;
  border: 1px solid #FFFFFF9A;
  text-decoration: none;

  &:hover,
  &:focus {
    color: #fff !important;
    background-color: #000000;
  }

  &::after {
    display: none;
  }
}

.navbar-expand-lg .navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.morePart {
  background: linear-gradient(180deg, #f1dc8d 0%, #d39f2e 103.56%);

  // border: 1px solid #FFFFFF2B;
  border: solid 1px #2d2c2c;
  border-radius: 20px;
  padding: 35px;
  opacity: 1;

  h3 {
    font-size: 25px;
    font-family: 'Roboto';
    color: #ffffff;
    margin-bottom: 0;
  }
}

.connectBtnMob {
  display: none !important;
}

.connectBtnDesktop {
  display: block;
}

@media (min-width: 991px) {
  .web-navbar {
    .account-menu {
      display: none;
    }

    .nav-link {
      img {
        // display: none;
      }
    }

    // a.nav-link {
    //   img {
    //     display: none;
    //   }
    // }

    .sub-account-menu {
      display: none;
    }

    .community-drp {
      display: block;
    }

    .header-social-icons {
      display: none;
    }
  }
}

.mobileMenuPart {
  display: none;
}

.logoMobile {
  display: none;
}

.themeBtnMob {
  display: none;
}


@media (max-width: 1399px) {
  nav.web-navbar a.navbar-brand {
    width: 142px;
  }

  .latestUpdatesPart .rightPart {
    width: 100%;
    height: 100%;
  }

  .latestUpdatesPart .rightPart img {
    width: 100%;
    height: 100%;
  }

  .navbar-full {
    height: 100vh;
    overflow-y: auto;
  }

  .navbar-toggler {
    padding: 0 !important;
  }

  nav.web-navbar .navbar-collapse {
    width: 100%
  }

  .mobileMenulast {
    order: 3;
    margin-top: 170px;
    width: 100%;
  }

  .themeBtn {
    display: none;
  }

  .themeBtnMob {
    display: flex;
    order: 4;
    font-family: 'Roboto';
    font-size: 12px;
    color: #fff;
    text-decoration: none;
    align-items: center;
    justify-content: center;
  }

  // nav.web-navbar .connect-btn {
  //   display: none;
  // }
  .web-navbar .btn {
    display: none !important;
  }

  .logoMobile {
    width: 200px;
    display: block;
  }

  nav.web-navbar {
    position: fixed !important;
    background: rgba($color: #000, $alpha: .5);
    opacity: 1;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    padding: 20px 0 !important;
    border: 1px solid #FFFFFF2B;
  }

  nav.web-navbar .navbar-main {
    position: relative !important;
    padding-top: 0rem !important
  }

  .mobileMenuPart {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .navbar-toggler {
      border: 0 !important;

      img {
        width: 26px;
        height: 26px;
      }
    }
  }

  nav.web-navbar .navbar-collapse {
    border-radius: 0px;
    background-color: transparent;
    border: 0;
    backdrop-filter: blur(0px);
    min-height: 100vh;
  }

  .navbar-brand {
    display: none;
  }

  .logo-pill {
    display: none;
  }

  .navCollaspeMobile {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .searchOrder {
    order: 1;
    width: 100%;
    padding: 30px 0 55px 0;
  }

  .menuOrder {
    order: 2;
    width: 100%;
  }

  nav.web-navbar .nav-link,
  nav.web-navbar a.nav-link,
  .nav-item {
    font-size: 20px;
    width: 100%;
    text-align: left !important;
  }

  nav.web-navbar a.nav-link {
    img {
      display: none;
    }
    span img {
      display: block;
    }

    &.active,
    &:hover {
      img {
        display: none;
      }
      span img {
        display: block;
      }
    }
  }

  nav.web-navbar .nav-link {
    font-size: 20px;
    width: 100%;
    text-align: left !important;
  }

  .community-drp .dropdown-toggle {
    margin-left: 0 !important;
  }

  .navCollaspeMobile .navbar-collapse .collapse .show {
    nav.web-navbar .connect-btn {
      width: 100%;
      display: block;
    }
  }

  nav.web-navbar a.nav-link {
    img {
      display: none;
    }
  }

  .header-social-icons {
    order: 3;
  }

  .connectBtnMob {
    display: block !important;
    width: 100%;
    padding: 10px !important;
    img {
      display: none;
    }

    .me {
      margin: 0 !important;
    }
  }

  .connectBtnDesktop {
    display: none !important;
  }
}

@media (max-width: 992px) {
  .morePart .col-auto {
    text-align: center;
    margin: 10px auto;
  }

  .latestUpdatesPart .rightPart img {
    border-radius: 10px 10px 0 0;
  }
  .dropdown-toggle::after {
    display: none !important;
  }
}
@media (max-width: 1399px) {
  nav.web-navbar a.nav-link {
    margin: 0 !important;
  }
  .rewards {
    width: 100%;
    .rewardsArrow {
      position: relative;
      width: 30px;
      height: 30px;
      img {
        top: 0 !important;
      }
    }
  }
}
.navBarHeight {
  height: 100%;
  overflow-y: scroll;
}
.scrollHide {
  overflow: hidden;
}

.navbar-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.twitter-handle-model .modal-dialog{
  margin: 5px;
  float: right;
}

.twitter-handle-model .modal-header {
  border-bottom: 0;
  padding: 25px 1rem;
}

.twitter-handle-model .modal-footer {
  padding: 30px;
  border-top: 0;
  padding-top: 0;
}

.twitter-handle-model .modal-body {
  padding: 30px;
  padding-top: 0;
}

.twitter-handle-model .modal-content {
  background: transparent;
  border: 1px solid #FFFFFF2B;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  border-radius: 20px;
}

.twitter-handle-model .btn-close {
  color: #ffffff !important;
  margin: 0 !important;
  height: 1.4rem !important;
  border: solid 1px #fff;
  border-radius: 100%;
  opacity: 1;
  // background: url(../../media/img/menu-close.svg);
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: center center;
}

.dropdown-toggle::after {
  display: none;
  margin-left: none;
  vertical-align: none;
  content: none;
  border-top: none;
  border-right: none ;
  border-bottom: none;
  border-left: none ;
}