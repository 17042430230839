.home-page{
    margin: 50px;
    border-radius: 20px;
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(5px);

    .title-text {
        color: #000000;
        font-size: 20px;
        font-weight: 700;
        font-family: 'Roboto';
    }

    .details-text {
        color: #000000;
        font-size: 16px;
        font-weight: 400;
        font-family: 'Roboto';
    }
}