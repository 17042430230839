@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "react-toastify/dist/ReactToastify.css";
body {
  font-family: "Roboto", sans-serif;
}
.nft-creator {
  // background: #020202;
  background-image: url("../assets/images/saudi_bg_image.png");
  min-height: 100vh;
}

.blkGradiant {
  background: linear-gradient(
    180deg,
    rgba(23, 23, 23, 0) 70%,
    rgba(2, 2, 2, 0.5) 80%,
    rgba(2, 2, 2, 0.75) 90%,
    rgba(2, 2, 2, 1) 100%
  );
  width: 100%;
}

.leftpart {
  position: sticky !important;
  min-height: 250px !important;
  // background: #1c1a1f;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  padding-top: 20px;
  padding-left: 25px;
  max-width: 200px !important;

  .content {
    padding-left: 20px;
  }

  ul {
    li {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;

      color: #000000;
      padding-left: 10px;
      margin-bottom: 30px;
      cursor: pointer;
      opacity: 0.6;

      .activeMenu {
        color: #000000 !important;
        opacity: 1 !important;
      }

      .disableMenu {
        color: #000000 !important;
        opacity: 0.7 !important;
      }

      &.active {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        opacity: 1;
      }
    }
  }

  .fontBold {
    font-size: 22px;
    font-weight: 600;
  }

  .stepText {
    font-size: 15px;
  }
}
.imgActive {
  opacity: 1 !important;
}

.structureContainer {
  h3 {
    font-family: "Roboto";
    line-height: 23px;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    color: #000000;
  }

  .icon {
    min-width: 123px;
    height: 123px;
    background: #1f1f1f 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
  }

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: #000000;
  }

  .subscription__button {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-left: 16px;
    padding-top: 16px;
    padding-right: 24px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 16px;
    cursor: pointer;
    min-height: 236px;
  }

  .subscription__button.active {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-left: 16px;
    padding-top: 16px;
    padding-right: 24px;
    opacity: 1;
    background: #1c1a1f 0% 0% no-repeat padding-box;
    border: 1px solid #a700db;
    border-radius: 16px;
    cursor: pointer;
  }
}

.grayBox {
  position: static;
  top: 190px !important;
  z-index: 8;
  background: rgba(255,255,255, 0.3);
  // background-color: #ffaf7970;
    backdrop-filter: blur(5px);
  padding: 20px;
  border-radius: 10px;
}

.btnGray {
  background: #77c75b;
  border-radius: 10px;
  opacity: 1;
  padding: 8px 22px !important;
  font-family: "Roboto";
  font-size: 16px;
  color: #ffffff !important;
  font-weight: 700;
  border: 0 !important;
  height: 44px !important;
}

.input-group-addon {
  width: 100% !important;
  padding: 2px;
}

.blackBox {
  display: flex;

  .tripleInp {
    display: flex;
    align-items: center;

    img {
      width: auto;
    }

    input {
      border: none;
      font-size: 12px;
    }
  }
}

.label-name {
  font-size: 16px;
  font-weight: 600;
  font-family: "Roboto";
  font-style: normal;
  letter-spacing: 0.23px;
  color: #000000;
  opacity: 1;
}

.input-text {
  border: 1px solid #ffffff2b;
  border-radius: 0px 23px 23px 0px;
  opacity: 1;
  padding: 10px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-text {
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  font-family: "Roboto";
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.subtitle-text {
  font: normal normal normal 15px/22px Roboto;
  letter-spacing: 0.23px;
  color: #ffffff;
  opacity: 1;
  max-width: 690px;
}

.step3 {
  .topDetailArea {
    .textTitle {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
    }
    .textDetails {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 35px;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
    }
    a.textDetails {
      font-family: "Roboto";
      font-style: normal;
      font-size: 20px;
      opacity: 1;
      color: #000000;
      // &:hover {
      //   color: #000000;
      // }
    }
  }
}

a.textDetails {
  font-family: "Roboto";
  font-style: normal;
  font-size: 14px;
  opacity: 1;
  color: #000000;
  &:hover {
    color: #77c75b;
  }
}

.whitelist-label {
  text-align: left;
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-family: "Roboto";
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.5;
}

.btnBlack {
  background: #e7a653e0;
  // border: 1px solid #c626f2;
  border-radius: 10px;
  opacity: 1;
  padding: 13px 26px !important;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  height: 44px !important;
}

.deploy-card {
  background: #020202 !important;
  border: none !important;
  font-size: 12px;
  color: #ffffff;
  font-weight: 600;
  img {
    width: 86px !important;
    height: 86px !important;
    border-radius: 10px;
    opacity: 1;
  }
  a.deploy-card-metadata {
    font-family: "Roboto";
    font-style: normal;
    font-size: 14px;
    opacity: 1;
    color: #27c2e1;
  }
}

.preview-nft-model {
  .modal-dialog {
    min-width: 700px !important;
  }
  .modal-header {
    border-bottom: 0;
    padding: 25px 1rem;
  }
  .modal-footer {
    padding: 30px;
    border-top: 0;
    padding-top: 0;
  }
  .modal-body {
    opacity: 1;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    padding: 30px;
    padding-top: 0;
    height: 650px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .modal-content {
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid #ffffff2b;
    border-radius: 23px;
    opacity: 1;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }
  .modal-title {
    font-family: "Roboto";
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    color: #000000;
  }
  .btn-close {
    color: #000000;
    margin: 0 !important;
    // background: #020202;
    border: solid 1px #fff;
    border-radius: 100%;
    opacity: 1;
    background: url(../media/img/menu-close.svg);
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: center center;
    height: 1rem !important;
  }
  &::-webkit-scrollbar {
    width: 2px;
    height: 3px;
  }
  &::-webkit-scrollbar-track {
    background: #888;
  }
  &::-webkit-scrollbar-thumb {
    background: #77c75b;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #77c75b;
  }
}
.preview-nft-metadata-model {
  .modal-dialog {
    min-width: 750px;
  }
  .modal-header {
    border-bottom: 0;
    padding: 25px 1rem;
  }
  .modal-footer {
    padding: 30px;
    border-top: 0;
    padding-top: 0;
  }
  .modal-body {
    opacity: 1;
    padding: 30px;
    padding-top: 10px;
  }
  .modal-content {
    background: #000000 0% 0% no-repeat padding-box;
    border: 1px solid #ffffff2b;
    opacity: 1;
    color: #ffffff;
  }
  .modal-title {
    font-family: "Roboto";
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    color: #ffffff;
  }
  .btn-close {
    color: #ffffff;
    margin: 0 !important;
    // background: #020202;
    border: solid 1px #fff;
    border-radius: 100%;
    opacity: 1;
    background: url(../media/img/menu-close.svg);
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: center center;
    height: 1rem !important;
  }
}
.deploy-card-title {
  font-family: "Roboto";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.deploy-card-status {
  font: normal normal 600 15px/22px Roboto;
  letter-spacing: 0.23px;
  color: #000000 !important;
  opacity: 0.8;
  cursor: pointer;
}

.blackBox {
  display: flex;
  align-items: center;

  .tripleInp {
    background-color: #020202;
    border-radius: 12px;
    // border: solid 1px #7070704d;
    display: flex;
    align-items: center;
    .customField {
      padding: 10px;
      min-height: 46px;
      border-radius: 9px;
      background: #121212 0% 0% no-repeat padding-box;
      border: 1px solid #ffffff2b;
      border-radius: 23px;
      opacity: 1;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      font-family: "Roboto";
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
    .input-text {
      background: #020202 0% 0% no-repeat padding-box;
      border: 1px solid #ffffff2b;
      border-radius: 0px 23px 23px 0px;
      opacity: 1;
      height: 46px;
      color: #fff;
    }

    .numberField {
      text-align: right;
    }

    svg {
      width: 100px;
      height: 20px;
    }

    input {
      color: #fff;
      border: none;
      &::placeholder {
        color: #fff;
      }
    }
  }
}

.layerImg {
  // background: #121212 0% 0% no-repeat padding-box;
  // border: 1px solid #ffffff2b;
  border-radius: 6px;
  width: 60px !important;
  height: 60px;
  position: relative;
  opacity: 1;

  svg {
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border-radius: 0px !important;
    border: none !important;
  }

  img {
    position: absolute;
    top: calc(50% - 69px);
    left: calc(50% - 69px);
    height: 60px;
    width: auto;
    overflow: hidden;
    border: 1px solid #ffffff;
    border-radius: 6px;
    opacity: 1;
  }

  input {
    opacity: 0;
    width: 60px;
    height: 60px;
    cursor: pointer;
    position: absolute;
  }
}
.darkGrayBackground {
  background: #121212 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff2b;
  border-radius: 12px;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto";
  color: #fff !important;
}

.validationText {
  font-size: 15px;
  color: red !important;
}

.removeImage {
  background-color: #1e1c22;
  border-radius: 10px;
  border: solid 2px black;
  color: white;
}

.card-image {
  border-radius: 10px;
  opacity: 1;
  height: 200px;
  padding: 5px;
}

.page-heading {
  margin: 215px 0 84px;
  position: relative;
}

.collection-details {
  min-height: 420px;
  @media (max-width: 767px) {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.hr {
  height: 205px;
  border: 1px solid #000000;
  opacity: 1;
  width: 0;
}

.br {
  @media (max-width: 767px) {
    margin-top: 20px;
    margin-bottom: 20px;
    width: calc(100vw - 68px);
    border: 1px solid #000000;
    opacity: 1;
    margin-left: -23px;
  }
}

.offerTable {
  background: #121212 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 20px;
}

.table-header {
  font-size: 26px;
  color: #ffffff;
  font-family: "Roboto";
}

.background-input-text {
  height: 40px;
  width: 100%;
  font-size: 14px !important;
  color: #000000 !important;
  // background: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  border: 0;
  opacity: 1 !important;
  padding-left: 15px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: 500;
  font-family: "Roboto";
  @media (max-width: 575px) {
    font-size: 10x !important;
    width: 100% !important;
  }
}

.total-minted {
  padding: 25px;
  border: 1px solid #000000;
  border-radius: 10px;
  opacity: 1;
  min-height: 205px;
  // background: rgba(255, 255, 255, 0.3);
  @media (max-width: 767px) {
    padding: 10px !important;
    margin-left: -21px !important;
    margin-right: 21px !important;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: fit-content;
  }
}

.mint-details {
  border: 1px solid #ffffff2b;
  border-radius: 10px;
  opacity: 1;
  min-height: 205px;

  .first-section {
    margin-top: 0px;
    @media (max-width: 767px) {
      margin-top: 10px;
    }
  }

  .third-section {
    @media (max-width: 767px) {
      margin-top: 0px;
      margin-bottom: 10px;
    }
  }

  @media (max-width: 767px) {
    margin-left: 21px !important;
    margin-right: 21px !important;
    padding: 10px !important;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: fit-content;
  }
}

.confirm-staking {
  background-image: linear-gradient(139deg, #eddfb2 0%, #edd37e 100%);
  padding: 5px 10px;
  border-radius: 22px;
  text-align: center;
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
  }
  span {
    // font-family: "Monument Extended";
    font-size: 15px;
    font-weight: 600;
    color: #3b3b3b;
    opacity: 1;
  }
}

.confirm-staking.loading {
  opacity: 0.5;
}

.nft-mint-status {
  display: flex;
  justify-content: center;
  align-items: center !important;
  flex-direction: column;
}

.header-title {
  font-family: "Roboto";
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  max-width: 320px;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 18px;
  }
}

.about-team {
  font-family: "Roboto";
  font-size: 29px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  background: transparent
    radial-gradient(
      closest-side at 99% 397%,
      #121212 0%,
      #121212 17%,
      #121212 100%
    )
    0% 0% no-repeat padding-box;
  border: 1px solid #ffffff2b;
  border-radius: 20px;
  opacity: 1;
  @media (max-width: 767px) {
    margin-left: 21px !important;
    overflow-x: scroll;
    overflow-y: hidden;
  }
}

.team-name {
  font-family: "Roboto";
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.nft-name {
  font-family: "Roboto";
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.mint-nftimage {
  height: 410px;
  width: 410px;
  border-radius: 30px;
  opacity: 1;
  border: 1px solid #000000;
  @media (max-width: 767px) {
    width: 334px;
  }
}

.collection-description-details {
  justify-content: start;
  align-items: flex-start;
  @media (max-width: 767px) {
    margin-top: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.total-items {
  @media (max-width: 767px) {
    margin-top: 10px;
  }
}

.about-details {
  font-family: "Roboto";
  font-size: 29px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.progress {
  background-color: #3b3b3b !important;
  height: 6px !important;
  .progress-bar {
    background-color: #77c75b !important;
    border-radius: 10px;
  }
}

.collection-tabel {
  border-color: #212529 !important;
  th {
    border-color: #212529 !important;
    color: #000000 !important;
    box-shadow: none;
  }
  td {
    color: #000000 !important;
    border-color: #212529 !important;
  }
}

.header-text {
  font-size: 14px;
  color: white;
}

.tabel-body-text {
  font-size: 14px !important;
  color: #000000;
}

.table-bordered td a {
  font-family: "Roboto";
  font-size: 14px !important;
  font-weight: 500;
  color: #ffffff !important;
}

.continueBtn {
  background: #77c75b;
  border-radius: 51px;
  padding: 6px 10px;
  opacity: 1;
  font-family: "Roboto";
  font-size: 15px;
  color: #ffffff !important;
  font-weight: 600;
  // border: 1px solid #c626f2;
  height: 36px !important;
}

.text-details {
  text-align: left;
  font: normal normal normal 12px Roboto !important;
  letter-spacing: 0.18px !important;
  color: #ffffff !important;
  opacity: 0.8 !important;
}

.background-details {
  // background: rgba(255, 255, 255, 0.3);
  border: 1px solid #433647;
  border-radius: 10px;
  // min-height: 64px;
  // max-height: fit-content;
  // overflow: hidden;

  .br {
    width: 100%;
    border: 1px solid #433647;
    opacity: 1;
  }
}

.rs-slider-bar {
  background-color: #3b3b3b !important;
  height: 6px !important;
}

.rs-slider-progress-bar {
  background: #77c75b !important;
  border-radius: 10px !important;
  opacity: 1 !important;
  height: 6px !important;
}

.rs-slider-handle::before {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  width: 14px !important;
  height: 14px !important;
  border: 0 !important;
}

.whiteBtn {
  background: #020202;
  border-radius: 10px !important;
  opacity: 1;
  font-family: "Roboto";
  font-size: 16px;
  color: #ffffff !important;
  font-weight: 600;
  border: 1px solid #ffffff99;
  height: 44px !important;
  letter-spacing: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 23px;
}

.date-text {
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  font-family: "Roboto";
  font-style: normal;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.btnGraySM {
  background: #77c75b;
  border-radius: 10px;
  opacity: 1;
  padding: 6px 22px !important;
  font-family: "Roboto";
  font-size: 18px;
  color: #ffffff !important;
  font-weight: 700;
  border: 0 !important;
  height: 37px !important;
}

.preview-nft {
  // background: rgba(255, 255, 255, 0.3);
  border: 1px solid #433647;
  border-radius: 10px;
  opacity: 1;
  width: 335px;
  height: 285px;
  display: flex;
  justify-content: center;
  img {
    width: 335px;
    height: 280px;
  }
  .layer-text {
    width: 335px;
    height: 280px;
    text-align: center;
    font: normal normal normal 15px/22px Roboto;
    letter-spacing: 0.23px;
    color: #ffffff;
    opacity: 0.5;
  }
}

.rarity-text {
  text-align: center;
  color: #000000;
}

.set-rarity {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.saveasdraft {
  position: sticky !important;
  min-height: 170px !important;
  top: 480px;
  border-radius: 10px;
  opacity: 1;
  max-width: 200px !important;
  background: rgba(255, 255, 255, 0.3);
  // border: 1px solid #433647;
  border-radius: 10px;
}

.shuffle-text {
  color: #000000;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}

.nftGeneratorFooter {
  position: fixed;
  bottom: -28px;
  z-index: 11;
  background-color: #FFFFFF;
  left: 0;
}

.middle-section {
  overflow-x: hidden;
}

.middle-section::-webkit-scrollbar {
  width: 5px;
  height: 8px !important;
}

.middle-section::-webkit-scrollbar-track {
  background: #000000;
  border-radius: 5px;
}

.middle-section::-webkit-scrollbar-thumb {
  background: #77c75b !important;
  border-radius: 5px;
}

.middle-section::-webkit-scrollbar-thumb:hover {
  background: #77c75b !important;
}

.shuffle-nft {
  position: sticky !important;
  top: 200px;
}

.bannerContentUp {
  position: relative;
  z-index: 99;
}

.walletConProfile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100vh;
}

.saveasdraftBtn {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  background: #77c75b;
  padding-bottom: 10px;
  padding-left: 23px;
  padding-right: 23px;
}

.draft-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #000000;
  opacity: 1;
  padding-top: 18px;
  padding-left: 40px;
  padding-right: 40px;
}

.save-time {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #000000;
  padding-left: 35px;
}
.attribute-block {
  background-color: #000000;
  box-shadow: 0px 0px 25px rgba(255, 255, 255, 0.07);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  .attribute-label {
    width: 100%;
    display: inline-block;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.5);
    @media (max-width: 575px) {
      font-size: 14px;
    }
  }
  .attribute-value {
    width: 100%;
    display: inline-block;
    font-family: "Roboto";
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    text-transform: capitalize;
    color: #ffffff;
    @media (max-width: 575px) {
      font-size: 12px;
    }
  }
  .attribute-trait {
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(255, 255, 255, 0.5);
    @media (max-width: 575px) {
      font-size: 13px;
    }
  }
}

.preview-nft-images {
  position: relative;
}

.custom-tabs {
  ul {
    border-bottom: none !important;
  }

  li {
    border-color: #edd37e !important;
  }

  button {
    padding: 0px !important;
    background-color: transparent !important;
    border: none !important;
    border-color: none !important;
    height: min-content !important;
    &:hover,
    &:active {
      border: 1px solid #edd37e !important;
      border-color: #edd37e !important;
    }
  }
}

// .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
//   background-color: #121212 !important;
//   border-color: #edd37e !important;
// }

.reactTimePicker .react-time-picker {
  width: 100%;
  height: 46px;
  background: #020202 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff2b;
  border-radius: 51px;
  padding: 0 10px;
  .react-time-picker__wrapper {
    border: 0;
    .react-time-picker__clear-button {
      display: none;
    }
    .react-time-picker__inputGroup__input,
    .react-time-picker__inputGroup__divider {
      font-family: "Roboto";
      font-size: 12px;
      color: #fff;
      text-align: center;
    }
    .react-time-picker__inputGroup__amPm {
      background: #020202 0% 0% no-repeat padding-box;
    }
  }
}

.customDatePicker {
  margin-top: 30px;
  .react-datepicker {
    background-color: transparent;
    border: 0;
  }
  .react-datepicker__header {
    background-color: transparent;
    border: 0;
    padding: 0;
  }
  .react-datepicker__navigation-icon::before {
    border-color: #fff;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-family: "Roboto";
    font-size: 12px;
    color: #fff;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    font-family: "Roboto";
    font-weight: normal;
    font-size: 12px;
    color: #ffffff !important;
    width: 22px;
    line-height: 22px;
    margin: 0.13rem;
  }
  .react-datepicker__day-names {
    margin-top: 20px;
  }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    background-color: transparent;
    color: #fff;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background: #121212 0% 0% no-repeat padding-box;
    color: #fff;
    border-radius: 100%;
  }
  .react-datepicker__navigation--previous {
    left: 0;
    top: 0;
    width: 28px;
    height: 28px;
  }
  .react-datepicker__navigation--next {
    right: 0;
    top: 0;
    width: 28px;
    height: 28px;
  }
}

.datePickerBackground {
  background: #121212 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff2b;
  border-radius: 23px;
  opacity: 1;
  padding: 20px;
  position: absolute;
  left: 100px;
  top: 5px;
  z-index: 1;
}

.home-rewards {
  text-align: center;
  display: flex;
  align-items: center;
  padding-top: 5rem !important;

  .container {
    position: relative;
    text-align: center;
    color: white;

    .rewardsDetails {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: -20px;
    }

    h1 {
      font-family: "Roboto" !important;
      font-size: 50px !important;
      color: #ffffff !important;

      @media (max-width: 768px) {
        font-size: 30px;
      }
    }

    .rewards-tabs-border {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .buy-button {
      background-image: linear-gradient(137deg, #eddfb2 0%, #edd37e 100%);
      padding: 6px 20px;
      margin-right: 1rem;
      margin-left: 1rem;
      border-radius: 25px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      border: none;
      span {
        margin-left: 0.5rem !important;
        // font-family: "Monument Extended";
        font-size: 16px;
        font-weight: 600;
        color: #3b3b3b;
        opacity: 1;
      }
    }

    .addwallet-button {
      border: 1px solid #ffffff2b;
      padding: 10px 20px;
      margin-right: 1rem;
      margin-left: 1rem;
      border-radius: 25px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      span {
        margin-left: 0.5rem !important;
      }
    }
  }

  .rewards-tabs-border {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .rewards-tabs {
    display: flex;
    justify-content: center;
    margin: 0;
    // padding: 25px;
    cursor: pointer;

    @media (max-width: 768px) {
      justify-content: inherit;
      // overflow: auto;
    }

    li {
      font-family: "Roboto";
      font-style: normal;
      display: inline;
      font-weight: bold;
      font-size: 18px;
      line-height: 30px;
      text-align: center;
      color: rgba(255, 255, 255, 0.5);
      text-align: center;
      position: relative;
      padding: 10px 20px;
      @media (max-width: 575px) {
        font-size: 14px;
        margin: 0;
      }

      a {
        color: rgba(255, 255, 255, 0.5);
      }

      .rewards-label {
        width: 100%;
        padding: 0 20px 20px 20px;
        display: inline-block;
        @media (max-width: 575px) {
          padding: 0 0 10px 10px;
          font-size: 12px;
        }
      }

      .active {
        width: 100%;
        display: inline-block;
        position: absolute;
        bottom: -10px;
        right: 0;
        left: 0;
        height: 20px;
        background-image: url("../assets/images/active-tab-icon.svg");
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 5%);
        border-radius: 25px;
        background-repeat: no-repeat;
        background-position: center;
        @media (max-width: 768px) {
          height: 41px;
          bottom: -20px;
        }
      }

      .tabActive {
        color: #fff;
      }
    }
  }
}

.home-rewards h1 {
  font-family: "Roboto" !important;
  font-size: 50px !important;
  color: #ffffff !important;

  @media (max-width: 768px) {
    font-size: 30px;
  }
}

.launchCollection {
  background: transparent
    radial-gradient(
      closest-side at 99% 397%,
      #27c2e1 0%,
      #27c2e1 17%,
      #121212 100%
    )
    0% 0% no-repeat padding-box;
  border: 1px solid #ffffff2b;
  border-radius: 20px;
  opacity: 1;
  height: 220px;
}

.collection-link:active:not(.btn),
.collection-link:hover:not(.btn),
.collection-link:link:not(.btn),
.collection-link:not(.btn),
.collection-link:not(.nav-link),
.collection-link:visited:not(.btn) {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4528dc;
  color: var(--primary-color);
  text-decoration: none;
}

.collection-card {
  border-radius: 10px;
  overflow: hidden;
  background-color: var(--collection-card-bg);
  display: block;
  position: relative;
  .vol p {
    position: absolute;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background: #00000033;
    border-radius: 100px;
    padding: 7px 20px;
    font-size: 15px;
    font-family: "Roboto";
    font-weight: 600;
    margin: 15px 20px;
    color: #ffffff;
  }
  .collection-img {
    display: block;
    max-height: 350px;
    min-height: 350px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .colleaction-info {
    background: transparent;
    position: relative;
    display: block;
    overflow: hidden;
    position: absolute;
    bottom: -14%;
    transition: all 0.5s;
    width: 100%;
    background: linear-gradient(
        359deg,
        rgba(0, 0, 0, 0.769),
        rgba(84, 84, 84, 0)
      )
      0 0 no-repeat;
    // height: 25rem !important;
    p {
      font-size: 15px;
      font-family: "Roboto";
      font-weight: normal;
    }
    .btn {
      font-size: 15px;
      font-family: "Roboto";
      color: #3b3b3b;
    }
  }

  .collection-icon {
    width: 37px !important;
    height: 37px !important;
    border-radius: 50%;
    top: -17px;
    position: absolute;
  }
  &:hover {
    .colleaction-info {
      // box-shadow: 0px 0px 25px rgba(255, 255, 255, 0.07);
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      bottom: 0;
      overflow: visible;
      .btn {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
      // h4 {
      //   color: #000000;
      // }
    }
  }
  h4 {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #ffffff;
    margin-top: 10px;
    margin-bottom: 0;
    margin-top: 0;
    text-shadow: 2px 2px 2px #000000;
  }
}
@media (max-width: 1400px) {
  .collection-card .colleaction-info {
    bottom: -40%;
  }
}
@media (max-width: 1200px) {
  .collection-card .colleaction-info {
    bottom: -51%;
  }
}
@media (max-width: 991.98px) {
  .collection-card .colleaction-info {
    bottom: -45%;
  }
}
@media (max-width: 767.98px) {
  .collection-card .colleaction-info {
    bottom: 0;
  }
}
@media (max-width: 575.98px) {
  .collection-card .colleaction-info {
    bottom: 0;
  }
}
@media (max-width: 436px) {
  .collection-card .colleaction-info {
    bottom: 0;
  }
}

.colleactionHeadd {
  margin-bottom: 10px;
  h4 {
    width: calc(100% - 30px);
  }
}
.linkfont {
  font-size: 15px;
  font-family: "Roboto";
  font-weight: normal;
  text-decoration: none;
}
.textGolden {
  color: #d39f2e;
  // color: #25bde1;
}

.nftmetadataview {
  width: 635px;
  position: relative;
  left: 0;
  border: 1px solid black;
  border-radius: 16px;
  opacity: 1;
  // background: rgba(255, 255, 255, 0.3);
}

.nft-card-details {
  border: 1px solid black;
  border-bottom: none;
  border-radius: 16px;
}

.wlParameters {
  .rp-input {
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    width: 22px;
    height: 22px;
    position: absolute;
    color: rgba($color: #fff, $alpha: 0.5);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #ffffff2b;
    border-radius: 23px;
    &:focus {
      color: #fff;
    }
  }
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  opacity: 0.4;
}

.close-btn {
  color: #ffffff;
  margin: 0 !important;
  // background: #020202;
  border: solid 1px #fff;
  border-radius: 100%;
  opacity: 1;
  height: 1.5rem !important;
}

.liveBtn {
  background: transparent linear-gradient(158deg, #eddfb2 0%, #edd37e 100%) 0%
    0% no-repeat padding-box !important;
  border-radius: 51px;
  opacity: 1;
  padding: 10px 14px !important;
  font-family: "Roboto";
  font-size: 11px;
  color: #3b3b3b !important;
  font-weight: 600;
  border: 1px solid #edd37e80 !important;
  height: 44px !important;
  line-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.startBtn {
  background: #020202;
  border-radius: 51px;
  opacity: 1;
  padding: 10px 14px !important;
  font-family: "Roboto";
  font-size: 11px;
  color: #ffffff !important;
  font-weight: 600;
  border: 1px solid #edd37e80 !important;
  height: 44px !important;
  line-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wltext {
  font-size: 10px;
}

.form-label {
  font-family: "Roboto";
  font-style: normal;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #000000;
  span {
    margin-top: 2px;
  }
  @media (max-width: 575px) {
    font-size: 12px;
  }
}

.form-input-text {
  width: 100%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
  // background: #292231 !important;
  background: #ffffff !important;
  border-radius: 10px !important;
  border: none !important;
  opacity: 1 !important;
  @media (max-width: 575px) {
    font-size: 12px !important;
    width: 100% !important;
  }
}

.form-input-text:focus {
  color: #000000 !important;
  box-shadow: none;
  border: none;
}

.form-control:focus {
  color: #000000 !important;
  box-shadow: none;
}

.chain-select {
  background-color: #121212 !important;
  border: 1px solid #ffffff2b !important;
  border-radius: 23px !important;
  opacity: 1 !important;
  padding: 10px;
  font-size: 18px !important;
  color: #ffffff !important;
}

.chain-select:focus {
  box-shadow: none;
  border: none;
}

.rp-form-label {
  font-family: "Roboto";
  font-style: normal;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 15px;
  span {
    margin-top: 2px;
  }
  width: 100%;
  display: inline-block;
}

.rp-input {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 28px;
  color: rgba($color: #fff, $alpha: 0.5);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff2b;
  border-radius: 23px;
  &:focus {
    color: #fff;
  }
}
.rp-input-links {
  display: flex;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
  background: #ffffff !important;
  border-radius: 10px !important;
  border: 0 !important;
  padding: 0 20px;
  justify-content: center;
  align-items: center;
  img {
    width: 20px;
    opacity: 0.5;
    height: 20px;
  }
  .rp-input-f-group {
    height: 37px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: #000000;
    background-color: transparent;
    border: 0 !important;
    &:focus-visible {
      outline: none !important;
    }
  }
}

.file-button {
  border-radius: 100%;
  // background: #1c1a1f;
  height: 200px;
  width: 200px;
  text-align: center;
  vertical-align: top;
  // border: 1px solid #c626f2;
  .plus-icon {
    cursor: pointer;
    display: inline-block;
  }
}
.banner-upload-btn {
  border: 1px solid #ffffff2b;
  border-radius: 10px;
  background: transparent;
  height: 200px;
  width: 250px;
  text-align: center;
  vertical-align: top;
}
.plus-icon {
  display: none;
}
.plus-icon:hover {
  cursor: pointer;
  display: inline-block;
}
.hidden-file {
  position: absolute;
  height: 0;
  width: 250px;
  opacity: 0;
}

.form-icon {
  font-size: 16px;
  color: #ffffff !important;
  background-color: #121212;
  border: 1px solid #ffffff2b;
  border-radius: 23px;
  opacity: 1;
  max-width: 100%;
  height: 45px;
  @media (max-width: 575px) {
    font-size: 12px;
    height: 35px;
  }
}

.form-text {
  color: #000000 !important;
  font-size: 12px !important;
  font-family: "Roboto";
  width: 100%;
  opacity: 1;
  @media (max-width: 575px) {
    font-size: 12px;
    margin-top: 5px;
  }
}

.upload-text {
  margin-top: 8px;
  margin-left: 10px;
  opacity: 0.8;
  width: 45%;
  @media (max-width: 1600px) {
    width: 40%;
    margin-top: 5px;
  }
  @media (max-width: 1256) {
    width: 30%;
    margin-top: 5px;
  }
  @media (max-width: 767px) {
    width: 70%;
    margin-top: 5px;
  }
}

.form-img {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 8px;
  margin-right: 15px;
  z-index: 1;
  opacity: 1;
  @media (max-width: 575px) {
    margin-top: 4px;
  }
}

.profile-info-text {
  font-family: "Roboto";
  font-size: 12px;
  color: rgba($color: #000000, $alpha: 0.7);
}

.text-danger {
  color: red !important;
}

.activities-table {
  width: 100%;
  th {
    padding: 10px;
    text-align: left !important;
    color: #ffffff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 10px;
    &.action {
      text-align: center !important;
    }
    @media (max-width: 768px) {
      font: normal normal normal 13px/16px Roboto;
    }
  }
  td {
    font-family: "Roboto";
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    text-align: left !important;
    color: #ffffff;
    padding: 10px;
    &:first-child {
      font-weight: normal;
      text-align: left;
      @media (max-width: 768px) {
        font: normal normal normal 13px/16px Roboto;
        padding: 10px 10px;
      }
    }
    .header-text {
      @media (max-width: 768px) {
        font: normal normal normal 13px/16px Roboto;
        padding: 5px 5px;
        opacity: 0.5;
      }
    }
    .body-text {
      @media (max-width: 768px) {
        font: normal normal normal 13px/16px Roboto;
        padding: 10px 10px;
      }
    }
    .c-info {
      img {
        width: 65px;
        height: 65px;
        border-radius: 6px;
        @media (max-width: 767px) {
          width: 41px;
          height: 40px;
        }
      }
      .c-name {
        font-family: "pp_monument_extendedregular";
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 28px;
        color: rgba(255, 255, 255, 0.5);
        opacity: 0.5;
        text-align: left;
      }
      span {
        display: inline-block;
        text-align: left;
      }
    }
  }
  .sol-p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    color: #ffffff;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @media (max-width: 767px) {
      font: normal normal normal 12px Roboto;
    }
    img {
      margin-left: 5px;
    }
  }
  .usd-p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    text-align: left;
    color: rgba(204, 204, 204, 0.3);
    display: inline-block;
    width: 100%;
    @media (max-width: 767px) {
      font: normal normal normal 12px Roboto;
    }
  }
  .success {
    color: #fff;
    @media (max-width: 767px) {
      font: normal normal normal 12px/16px Roboto;
    }
  }
}

.rp-pagination {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  color: #ffffff;
  .page-numbers {
    font-weight: 600;
    color: #000000;
  }
  .page-changer {
    display: flex;
    align-items: center;
    button {
      background: #161616;
      padding: 5px;
      border: none;
      &:disabled {
        opacity: 0.5;
      }
    }
    .current-page {
      color: #000000;
      margin: 0 15px;
    }
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #ffffff;
  }

  .page-changer-right {
    display: flex;
    justify-content: end;
  }

  .page-counter {
    display: flex;
    align-items: center;
    color: #000000;
  }

  .page-input {
    background: #161616;
    border: 0.5px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    position: relative;
    margin-left: 10px;
    color: #ffffff;
  }

  .page-input input {
    background: transparent;
    height: 50px;
    padding: 0 20px 0 20px;
    width: 90px;
    border: 0;
  }

  .left-arrow {
    transform: rotate(180deg);
    margin-left: 10px;
  }

  .right-arrow {
    margin-right: 10px;
  }
}

.upload-text-details {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
}

.wallet-address-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
}

.stylishScrollBar ::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.stylishScrollBar ::-webkit-scrollbar-track {
  background: #000000;
}

/* Handle */
.stylishScrollBar ::-webkit-scrollbar-thumb {
  background: #77c75b;
}

/* Handle on hover */
.stylishScrollBar ::-webkit-scrollbar-thumb:hover {
  background: #77c75b;
}

.gray-border {
  border: 1px solid rgb(245, 215, 215);
  border-radius: 16px;
}

.rp-button {
  // background: #ffffff;
  background: linear-gradient(180deg, #f1dc8d 0%, #d39f2e 103.56%) !important;
  border-radius: 51px;
  color: #3b3b3b !important;
  font-family: "pp_monument_extendedblack";
  font-size: 15px;
  padding: 15px 45px;
  display: inline-block;
  border: 0;
  &:disabled {
    // opacity: 0.2;
    background: #474747;
    cursor: not-allowed;
  }
}

@media (max-width: 992px) {
  h1 {
    font-size: 26px;
  }
}

.custom-card {
  border-radius: 15px;
  // background: rgba(255, 255, 255, 0.3);
  // backdrop-filter: blur(5px);
  .card-body {
    border-radius: 0 0 15px 15px;
  }
  img {
    height: 300px;
    width: 100%;
    border-radius: 15px 15px 0 0;
  }
}

.mint-nfts {
  position: sticky !important;
  top: 630px;
  border-radius: 10px;
  opacity: 1;
  max-width: 200px !important;
  // background: #1C1A1F;
  background: rgba(255, 255, 255, 0.3);
  // border: 1px solid #433647;
  border-radius: 10px;
  min-height: 80px;
}
.table {
  --bs-table-bg: transparent !important;
}